import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Navbar.css';  // Import your custom CSS file
import logo from '../../assets/images/logo.png';
import { Link } from 'react-scroll'; // Import Link from react-scroll

const CustomNavbar = () => {
  return (
    <Navbar className="custom-navbar" expand="lg" fixed='top'> {/* Add a custom class name */}
      <Navbar.Brand href="#home">
        <img
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="GUVI logo"
        />
        WisdomSprouts
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav ">
        <Nav className="ml-auto">
          {/* Use Link from react-scroll instead of Nav.Link */}
          <Nav.Link as={Link} to="overview" spy={true} smooth={true} offset={-70} duration={300}>Overview</Nav.Link>
          <Nav.Link as={Link} to="syllabus" spy={true} smooth={true} offset={-70} duration={300}>Syllabus</Nav.Link>
          <Nav.Link as={Link} to="certification" spy={true} smooth={true} offset={-70} duration={300}>Certification</Nav.Link>
          <Nav.Link as={Link} to="placement" spy={true} smooth={true} offset={-70} duration={300}>Placement</Nav.Link>
          <Nav.Link as={Link} to="faq" spy={true} smooth={true} offset={-70} duration={300}>FAQ</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
