import React, { useState, useRef, useEffect } from 'react';
import './ConnectWithExperts.css';

const data = [
  { name: 'Prasannakkumar C', title: 'Backend Engineer', company: 'Myntra', image: 'https://via.placeholder.com/100' },
  { name: 'Sidhartha Sarangi', title: 'Software Development Engineer 3', company: 'Walmart', image: 'https://via.placeholder.com/100' },
  { name: 'Harshit', title: 'Software Development Engineer', company: 'Swiggy', image: 'https://via.placeholder.com/100' },
  { name: 'M Venkata Gopichand', title: 'Tech Lead', company: 'Tech Mahindra', image: 'https://via.placeholder.com/100' },
  { name: 'KM Akbar Basha', title: 'Technical Team Lead', company: 'Animaker', image: 'https://via.placeholder.com/100' },
  { name: 'Mallikarjun', title: 'Senior Software Engineer', company: 'Infosys', image: 'https://via.placeholder.com/100' },
  { name: 'Amit Sharma', title: 'DevOps Engineer', company: 'Amazon', image: 'https://via.placeholder.com/100' },
  { name: 'Sneha Gupta', title: 'Frontend Developer', company: 'Flipkart', image: 'https://via.placeholder.com/100' },
  { name: 'Rohit Singh', title: 'Full Stack Developer', company: 'Google', image: 'https://via.placeholder.com/100' },
  { name: 'Anjali Verma', title: 'Software Architect', company: 'Microsoft', image: 'https://via.placeholder.com/100' },
  { name: 'Rahul Mehta', title: 'Mobile Developer', company: 'Paytm', image: 'https://via.placeholder.com/100' },
  { name: 'Priya Kapoor', title: 'Data Scientist', company: 'Facebook', image: 'https://via.placeholder.com/100' },
  { name: 'Karan Patel', title: 'Backend Engineer', company: 'Netflix', image: 'https://via.placeholder.com/100' },
  { name: 'Vikram Chauhan', title: 'Software Engineer', company: 'Uber', image: 'https://via.placeholder.com/100' },
];

const Card = ({ name, title, company, image }) => (
  <div className="card1">
    <h4 className='card1Name'>{name}</h4>
    <p className='card1Title'>{title}</p>
    <p className='card1Title' ><strong>{company}</strong></p>
    <div className="spacer"></div>
    <img src={image} alt={name} className="profile-image" />
  </div>
);

const App = () => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const cardsContainerRef = useRef(null);

  const scrollLeft = () => {
    if (cardsContainerRef.current) {
      cardsContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
      setScrollIndex(prev => Math.max(prev - 1, 0));
    }
  };

  const scrollRight = () => {
    if (cardsContainerRef.current) {
      cardsContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
      setScrollIndex(prev => Math.min(prev + 1, data.length - 1));
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (cardsContainerRef.current) {
        const scrollLeft = cardsContainerRef.current.scrollLeft;
        const cardWidth = cardsContainerRef.current.firstChild.offsetWidth;
        setScrollIndex(Math.round(scrollLeft / cardWidth));
      }
    };

    const ref = cardsContainerRef.current;
    if (ref) {
      ref.addEventListener('scroll', handleScroll);
      return () => ref.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className="app1">
      <h1 className='overview2TitleText'>Connect with our Experts</h1>
      <h1 className='overview2TitleTextphone'>Connect with our Experts</h1>
      <p>Learn from India's top Industry Leaders</p>
      <div className="cards-wrapper">
        {scrollIndex > 0 && (
          <button className="nav left" onClick={scrollLeft}>&lt;</button>
        )}
        <div className="cards-container" ref={cardsContainerRef}>
          {data.map((person, index) => (
            <Card key={index} {...person} />
         
          ))}
        </div>
        {scrollIndex < data.length - 1 && (
          <button className="nav right" onClick={scrollRight}>&gt;</button>
        )}
      </div>
    </div>
  );
};

export default App;
