import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IoIosArrowDropupCircle,IoIosArrowDropdownCircle } from "react-icons/io";
import './FAQuestions.css';

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqs = [
    { question: "I’m from a non-engineering background or new to programming. Can I take this course?", 
      answer: "We aim to create job opportunities for all students. We offer a PreBootcamp to assess your readiness for the Zen Bootcamp. If you need extra preparation, our mentors will guide you with additional GUVI lessons." },
    {
      question: "What will be tested to clear the PreBootcamp test?",
      answer: `
        <ul>
          <li>Basic Level Questions:</b> This will help us identify your ability to read and debug the given code. Your comfort level around understanding loops & conditional statements will be evaluated.</li>
          <li>Advanced Coding Question:</b> Here we will try to understand your current knowledge of coding in order to place you in the classroom & customize the program schedule to your needs.</li>
          <li>Basic Aptitude:</b> We will analyze your basic communication & aptitude skills as early as possible. These skills will help us in arranging the mock interviews after the Main Bootcamp.</li>
        </ul>`
    },
    {
      question: "Which companies will I be given opportunities under Placement Guidance?",
      answer: "You will have potential opportunities with over 1,000 companies through our Placement Guidance, including prominent names like PayPal, Freshworks, Zoho, JusPay, DBS, Scapic, OrangeScape, and many more."
    },
    { question: "What is the duration of this course?",
       answer: "Online Live Batch: 3 Months (Monday to Friday) / 5 Months (Saturday & Sunday)" },
    { question: "What are the payment options?",
       answer: "For details regarding the payment, you may contact +91 9736097320." },
    { question: "What is the capstone Project all about and how will it help me?", 
      answer: "As part of the Capstone Project, the participants are required to build their own application by the end of the course which can be added to their GitHub profile. With an emphasis on learning by doing, the bootcamp course helps participants working on building their application from the 1st week itself. At the end, the participant builds his own application understanding the software development process, code review process, and also learns the best practices in coding and utilization of Cloud & DevOps tools etc." },
    { question: "What is MEAN and MERN stack?", 
      answer:
       `
       <ul>
          <li>MEAN - </b> MongoDB, ExpressJs, AngularJs & NodeJs</li>
          <li>MERN - </b> MongoDB, ExpressJs, ReactJs & NodeJs</li>
           </ul>
        `
      
      
       },
    { question: "What is meant by Full Stack Development Course?", 
      answer: "The Full-stack Development Course systematically covers the fundamental technologies essential for aspiring Full Stack Developers. It comprises five distinct segments: Frontend, Backend, Database, other essential technologies, and Debugging/Version Control." },
    { question: "Will I get a refund after joining the Main Bootcamp?", 
      answer: "No, you will not be able to claim the refund once you've entered the Main Bootcamp phase." },
  ];

  const toggleFAQ = index => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className="faq-section">
      <h2 className="overview2TitleText , FaqHeadText">Frequently Asked Questions</h2>
      <h2 className="overview2TitleTextphone">Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${expandedIndex === index ? 'expanded' : ''}`}
          onClick={() => toggleFAQ(index)}
        >
          <div className="faq-question">
            <text className='faq-question-text'>
            {faq.question}
            </text>
            <div className="iconDiv">
            {expandedIndex === index ? <IoIosArrowDropupCircle style={{width:'20px',height:'20px'}} /> : <IoIosArrowDropdownCircle style={{width:'20px',height:'20px'}}  />}
            </div>
          </div>
          {expandedIndex === index && (
            <div
              className="faq-answer"
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
