import React from 'react';
import PropTypes from 'prop-types';
import './movinglogo.css'

const Card = ({ image, title }) => (
  <div className="card text-center">
    <img src={image} alt={title} className="card-img-top" style={{ maxWidth: '100%',minHeight:'60px' }} />
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
    </div>
  </div>
);

Card.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Card;
