import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './OurLearners.css';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import userimg from '../../../assets/images/tempicon1.png'

const projects = [
  {
    title: "Bunty",
    description: "Build your own version of Spotify, where you can search, play, and curate music playlists. You will learn more about real-time streaming tech and how to manage user accounts smoothly.",
  },
  {
    title: "Shunty",
    description: "Create a personal finance tracker like Khatabook that allows users to monitor their income, expenses, and savings goals. Users can categorize transactions, generate reports, and set budget limits. Include features like data visualization for spending trends, user authentication, and secure data storage.",
  },
  {
    title: "Aunty",
    description: "Build an e-commerce website where users can browse products, add them to their cart, and make purchases. The frontend will feature product listings, search functionality, shopping cart management, and checkout processes.",
  },
  {
    title: "Ghanty",
    description: "Bored of your Gmail UI? Build your own frontend for Gmail by accessing it through the Gmail API. This project will teach you how to do API integration and OAuth.",
  },
  
];

const ProjectsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3.9,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="unique-projects-carousel">
      <h2 className="overview2TitleText">Our Learners</h2>
      <h2 className="overview2TitleTextphone">Our Learners</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="unique-card">

            <div className="unique-card-head">
             <span style={{ transform: 'scaleX(-1)',width:'100%', display:'flex',alignItems:'flex-end',justifyContent:"flex-end"}}>
        <FaQuoteRight color='lightgrey' style={{width:'30px',height:'30px'}} />
             </span>
         
            <p>{project.description}</p>
            </div>

            {/* <div className="uiquespacer"></div> */}

            <div className="card-userProfile">
                <div className="card-userProfileImg">
                <img className='learningUserImage' src={userimg} alt='img'/>
                </div>
                <h3>{project.title}</h3>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProjectsCarousel;
