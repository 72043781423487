import React from 'react'
import './AboutOverView.css'
import tempicon1 from '../../assets/images/tempicon1.png'

function AboutOverView() {
    return (
        <div className='AboutOverView'>


            <div className="overview2TitleContainer">
                <text className="overview2TitleText">
                    About GUVI’s Full Stack Development Course with AI Tools
                </text>
                <text className="overview2TitleTextphone">
                    About GUVI’s Full Stack Development Course with AI Tools
                </text>
                <text className="overview2SubTitleText">
                    Our IIT-M Pravartak Certified Full Stack Development Course with AI Tools offers a pinnacle of real-time learning and expert mentorship. Harness our AI-powered platforms for round-the-clock project guidance and customized interview preparation. From exciting hackathons to insightful webinars and a supportive community, this course offers it all, with learning options in Tamil, Kannada, Hindi, and English. Step into the Full-stack development industry with confidence and a network of industry leaders to back you.
                </text>
            </div>




            <text className="overview2TitleTextphone ">
                Program Overview
            </text>

            <div className="aboutInfoBox">


                <text className="overview2TitleText">
                    Program Overview
                </text>

                <div className="aboutMenuContainer">

                    <div className="aboutParent">
                        <div className="aboutParentLogo">
                            <img src={tempicon1} />

                        </div>

                        <div className="aboutParentTitle">
                            1-on-1 Mock Interviews with Industry Experts
                        </div>
                    </div>


                    <div className="aboutParent">
                        <div className="aboutParentLogo">
                            <img src={tempicon1} />

                        </div>
                        <div className="aboutParentTitle">
                            Unlock Your Career Potential with Our Placement Guidance
                        </div>
                    </div>


                    <div className="aboutParent">
                        <div className="aboutParentLogo">
                            <img src={tempicon1} />

                        </div>
                        <div className="aboutParentTitle">
                            Learn AI tools to Code faster, better, and more precisely.
                        </div>
                    </div>




                    <div className="aboutParent">
                        <div className="aboutParentLogo">
                            <img src={tempicon1} />

                        </div>
                        <div className="aboutParentTitle">
                            EMI options available(Upto 12 months) with 7-day Refund Policy
                        </div>
                    </div>



                    <div className="aboutParent">
                        <div className="aboutParentLogo">
                            <img src={tempicon1} />

                        </div>
                        <div className="aboutParentTitle">
                            Live online classes and Lifetime Access to Recorded Sessions
                        </div>
                    </div>



                    <div className="aboutParent">
                        <div className="aboutParentLogo">
                            <img src={tempicon1} />

                        </div>
                        <div className="aboutParentTitle">
                            Unlimited access to Programming Practice Platforms with 1500+ Problem Statements
                        </div>
                    </div>




                    <div className="aboutParent">
                        <div className="aboutParentLogo">
                            <img src={tempicon1} />

                        </div>
                        <div className="aboutParentTitle">
                            Advance in Coding with 100+ Hours of Industry-Relevant Practical Projects
                        </div>
                    </div>




                </div>



            </div>



            <div className="EnjoyOverView">

                <text className="overview2TitleText ">
                    Enjoy our AI-integrated Learning
                </text>
                <text className="overview2TitleTextphone ">
                    Enjoy our AI-integrated <br/> Learning
                </text>

                <div className="EnjoyOverViewCardContainer">
                    <div className="EnjoyOverViewCard">
                        <div className="EnjoyOverViewCardLogo">

                            <img src={tempicon1} />
                        </div>
                        <div className="EnjoyOverViewCardText">
                            <text>
                                Get 24/7 AI-chat support for project-related doubts
                            </text>
                        </div>


                    </div>



                    <div className="EnjoyOverViewCard">
                        <div className="EnjoyOverViewCardLogo">

                            <img src={tempicon1} />
                        </div>
                        <div className="EnjoyOverViewCardText">
                            <text>
                            Leverage AI tools to accelerate your learning and coding efficiency
                            </text>
                        </div>


                    </div>




                    <div className="EnjoyOverViewCard">
                        <div className="EnjoyOverViewCardLogo">

                            <img src={tempicon1} />
                        </div>
                        <div className="EnjoyOverViewCardText">
                            <text>
                            Practice mock interviews with the AI interview tool
                            </text>
                        </div>


                    </div>



                </div>




            </div>




        </div>
    )
}

export default AboutOverView
