import React from 'react'
import './Downloadsyllabus.css'



function Downloadsyllabus() {
  return (
    <div className='downloadsyllabus'>
      <text className='downloadsyllabusTitle'>
      Looking for in-depth Syllabus Information?
      </text>
      <text className='downloadsyllabusSubTitle'>
      Explore your endless possibilities in Full-stack Development with our Brochure!
      </text>

      <button className='downloadsyllabusbutton'>
        Download Syllabus
      </button>
    </div>
  )
}

export default Downloadsyllabus
