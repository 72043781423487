// src/components/CompanyCarousel.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './CampusCompany.css';

// import paypalLogo from './assets/paypal.png'; // Replace with the correct path to your logo
// import freshworksLogo from './assets/freshworks.png';
// import tataLogo from './assets/tata.png';
// import scapicLogo from './assets/scapic.png';
// import chargebeeLogo from './assets/chargebee.png';
// import flipkartLogo from './assets/flipkart.png';
// import clayfinLogo from './assets/clayfin.png';
// import oneDPOLogo from './assets/oneDPO.png';
// import slqsessLogo from './assets/slqsess.png';
import testimg from '../../../assets/images/tempicon1.png'

const CompanyCarousel = () => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows:false,
        responsive: [
            
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                }
            },
           
        ]
    };

    return (
        <div className="CampusCompanyContainer">
            <div className="campusTitleContainer">
             <text className="overview2TitleText">
             Where do our students work?
                </text>
                <text className="overview2TitleTextphone">
                Where do our students work?
                </text>
                </div>


                <div className="logosContainer">

            <Slider {...settings}>

            
                <div>
                    <img src={testimg} alt="PayPal" />
                </div>
                <div>
                    <img src={testimg} alt="Freshworks" />
                </div>
                <div>
                    <img src={testimg} alt="Tata Communications" />
                </div>
                <div>
                    <img src={testimg} alt="Scapic" />
                </div>
                <div>
                    <img src={testimg} alt="Chargebee" />
                </div>
                <div>
                    <img src={testimg} alt="Flipkart" />
                </div>
                <div>
                    <img src={testimg} alt="Clayfin" />
                </div>
                <div>
                    <img src={testimg} alt="OneDPO" />
                </div>
                <div>
                    <img src={testimg} alt="SLQSess" />
                </div>
            </Slider>
                </div>
        </div>
    );
};

export default CompanyCarousel;
