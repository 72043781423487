import React from 'react';
import Slider from 'react-slick';
import './HappyLearner.css';

const CarouselComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const learners = [
    { name: 'Akhil', title: 'Full Stack Developer', videoId: 'cB-ZRijjIMY' },
    { name: 'Nathiya Ayyadurai', title: 'Engineer', videoId: 'cB-ZRijjIMY'},
    { name: 'Manikanta', title: 'Software Engineer', videoId: 'cB-ZRijjIMY' },
    { name: 'Sowmyal', title: 'Developer', videoId:'cB-ZRijjIMY' },
  ];

  return (
    <div className="carousel-container">
      <h2 className='happyLearnersTitle'>Our Happy Learners</h2>
      <Slider {...settings}>
        {learners.map((learner, index) => (
          <div key={index}>
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${learner.videoId}`}
              title={`YouTube video player - ${learner.name}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* <p>{`${learner.name} - ${learner.title}`}</p> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '-25px' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '-25px' }}
      onClick={onClick}
    />
  );
};

export default CarouselComponent;
