import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './components/Home';
import Dashboard from '../Components/Dashboard/Dashboard';
import Footer from '../Components/Footer/Footer';
import Navbar from '../Components/Navbar/Navbar';

const Navigation = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          {/* <Route path="/Footer" element={<Footer />} /> */}
          {/* <Route path="/Footer" element={<Footer />} /> */}
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default Navigation;
