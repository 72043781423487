import React, { useState } from 'react';
import './Overview.css';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';



const handleChange = (value) => {
    console.log(value); // Handle phone number changes here
};

function OverView() {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    return (
        // <div className="mainContaiener">
        <div className='overViewContainer'>
            {/* <div className="overViewAdjust"> */}
                <div className="overviewContentWrapper">
                    <div className="overViewInfo">

                        <div className="overViewInfoTitle">
                            <text>
                                IITM Pravartak certified Full Stack Development Course with <text style={{ color: '#ffc107' }}>AI Tools</text>
                            </text>
                        </div>

                        <div className="overViewInfoSubTitle">
                            <text>
                                Mentorship from Industry Experts at Microsoft, Walmart, Swiggy, Uber, Myntra & more!
                            </text>
                        </div>
                        <div className="overViewInfoContent">
                            <text>
                                Transform your future in Full-stack Development with our Zen-Class Placement Guidance. Learn in English, தமிழ், ಕನ್ನಡ, हिंदी and gain Hands-on experience through Real-time Projects, setting you up for a Successful Career
                            </text>

                        </div>

                    </div>
                    <div className="overViewForm">

                        <form className='interestedForm'>
                            <div className='interestedFormLable'>
                                <text>
                                    I’m Interested
                                </text>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputName" className="form-label">Name</label>
                                <input type="text" className="form-control" id="exampleInputName" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                            </div>
                            <div className="mb-3  align-items-center">
                                <label htmlFor="exampleInputPhone" className="form-label me-3">Phone </label>
                                <div className="input-group flex-grow-1">
                                    <PhoneInput
                                        country="IN" // Default country code
                                        id="exampleInputPhone"
                                        className="form-control-country"
                                        onChange={handleChange}
                                        // placeholder="Enter phone number"
                                        defaultCountry='IN'

                                        countryCallingCodeEditable={true}
                                        displayInitialValueAsLocalNumber={true}

                                        enableAreaCodes={true}



                                    />


                                </div>
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={handleCheckboxChange} />
                                <label className="form-check-label" htmlFor="exampleCheck1">Do you have a discount coupon?</label>


                            </div>
                            {isChecked && (
                                <div className="couponeCodeContainer">
                                    <input className='couponCode' />
                                    <button type='button' className="btn1 btn-success1">Apply</button>
                                </div>
                            )}
                            <button type="submit" className="btn btn-success">Submit</button>
                        </form>

                    </div>
                </div>

                <div className="overViewCardContainer">

                    <div className="overViewCard">
                        <text className="cardTitle">
                            Duration
                        </text>
                        <text className="cardCOntent">
                            3 Months (Weekdays) / 5 <br />
                            Months (Weekends)
                        </text>
                    </div>





                    <div className="overViewCard">
                        <text className="cardTitle">
                            Format
                        </text>
                        <text className="cardCOntent">
                            Online
                        </text>
                    </div>





                    <div className="overViewCard">
                        <text className="cardTitle">
                            Placement
                        </text>
                        <text className="cardCOntent">
                            Placement Guidance
                        </text>
                    </div>





                    <div className="overViewCard">
                        <text className="cardTitle">
                            Hiring Partners
                        </text>
                        <text className="cardCOntent">
                            200+ Companies
                        </text>
                    </div>






                    <div className="overViewCard">
                        <text className="cardTitle">
                            EMI Options
                        </text>
                        <text className="cardCOntent">
                            Upto 12 months
                        </text>
                    </div>






                    <div className="overViewCard">
                        <text className="cardTitle">
                            Course
                        </text>
                        <text className="cardCOntent">
                            Live Online Classes + Lifetime <br />
                            access to Recorded Videos
                        </text>
                    </div>




                </div>

            {/* </div> */}




            {/* <div className='overview2Conatiner'>

                <div className="overview2TitleContainer">
                    <text className="overview2TitleText">
                        About GUVI’s Full Stack Development Course with AI Tools
                    </text>
                    <text className="overview2SubTitleText">
                        Our IIT-M Pravartak Certified Full Stack Development Course with AI Tools offers a pinnacle of real-time learning and expert mentorship. Harness our AI-powered platforms for round-the-clock project guidance and customized interview preparation. From exciting hackathons to insightful webinars and a supportive community, this course offers it all, with learning options in Tamil, Kannada, Hindi, and English. Step into the Full-stack development industry with confidence and a network of industry leaders to back you.
                    </text>
                </div>

              <div className="aboutInfoBox"></div>


            </div> */}











        </div>







        // </div>
    );
}

export default OverView;
