import React from 'react';
import './Placement.css';
import tick from '../../assets/images/tick.png';
import dot from '../../assets/images/whiteDot.png';

function Placements() {
  const placementItems = [
    "Enroll in our Full Stack Development Program",
    "Attend Live classes + Pursue self-paced learning",
    "Complete the projects assigned by Industry Experts",
    "Secure a Digital Portfolio in “Github”",
    "Attend Mock Interviews with our HR team & Technical Round with Industry Experts",
    "Receive Interview opportunities with Companies",
    "Attend & clear the Interview with splendid packages"
  ];

  const programDetails = [
    "PreBook your seat by Paying ₹ 8000 (Refundable) and Attend our Prebootcamp Session.",
    "Up to 12 months EMI* option Available",
    "Learn at your own Pace",
    "Evaluate the Prebootcamp (Free Sessions) with 100% Refund Guarantee",
    "7-Day Refund Policy"
  ];

  return (
    <div className='PlacementContainer'>
      <div className="placementTitleContainer">
        <text className="overview2TitleText">
          Placement Guidance
        </text>
        <text className="overview2TitleTextphone">
          Placement Guidance
        </text>
        <text className="placementSubTitleText">
          Benefit from real-time interview experiences, placement guidance & mentorship from top industry professionals to confidently navigate your job search.
        </text>
      </div>

      <div className="placementInfoContainer">
        <h6 className='placementInfoTitle'>
          How does our Program work?
        </h6>

        {placementItems.map((item, index) => (
          <div key={index} className="placementInfo">
            <img className='placementTick' src={tick} alt="tick icon" />
            <text>{item}</text>
          </div>
        ))}
      </div>
      
      <div style={{backgroundColor:'#102618'}} className="programtInfoContainer">
        <h4 className='programInfoTitle'>
          Program Fee:
        </h4>
        <h4 className='programInfoTitle'>
          <text className='programActualPrice'>
            1,25,000
          </text>
          <text  style={{fontWeight:700,marginLeft:'10px'}}>
            ₹ 94,999 {' '}
            <text className='changeColorPrice'>(Inclusive of GST) </text> 
          </text>
        </h4>

        <div className="programDetailContainer">
          {programDetails.map((detail, index) => (
            <div key={index} className="programDetail">
              <img className='programDot' src={dot} alt="dot icon" />
              <text className='programDetailText'>
                {detail.includes("₹ 8000 (Refundable)") ? (
                  <>
                    PreBook your seat by Paying <span style={{ color: "#FFC107" }}>₹ 8000 (Refundable)</span> and Attend our Prebootcamp Session.
                  </>
                ) : (
                  detail
                )}
              </text>
            </div>
          ))}
        </div>
      </div>

      <div className="placementButtonContainer">
              <button className='placementContactBtn'>Contact Us</button>
              <button className='placementPrebookBtn'>Prebook Now</button>

      </div>
    </div>
  );
}

export default Placements;
