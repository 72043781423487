import React from 'react'
import './EnrollNow.css'
import image1 from '../../../assets/images/tempicon1.png'

function EnrollNow() {
  return (
    <div className='enrollNowContainer'>
      <div className="enrollNowtextContainer">
        <text className='enrollNowtext'>
        Online Learning with Weekend/Weekday Live classes and Mentoring Sessions
        </text>
        <div className="enrollNowBtnContainer">
                    <button className='enrollNowBtn'>
                    Enroll Now
                     </button>
                    <button className='enrollNowCallBAckBtn'>
                   Request call back
                     </button>
        </div>
      </div>
      <div className="enrollNowImg">
            <img src={image1}/>
      </div>
    </div>
  )
}

export default EnrollNow
