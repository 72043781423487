import React from 'react';
import Slider from 'react-slick';
import Card from './card';
import nodeJsImage from '../../../assets/images/tempicon1.png'; // Add your images in the src/images directory
import expressJsImage from '../../../assets/images/tempicon1.png';
import mongooseImage from '../../../assets/images/tempicon1.png';
import postmanImage from '../../../assets/images/tempicon1.png';
import vsCodeImage from '../../../assets/images/tempicon1.png';
import awsImage from '../../../assets/images/tempicon1.png';
import htmlImage from '../../../assets/images/tempicon1.png';
import cssImage from '../../../assets/images/tempicon1.png';
import tailwindCssImage from'../../../assets/images/tempicon1.png';
import dockerImage from '../../../assets/images/tempicon1.png';
import kubernetesImage from '../../../assets/images/tempicon1.png';
import gitImage from '../../../assets/images/tempicon1.png';
import githubImage from '../../../assets/images/tempicon1.png';
import netlifyImage from '../../../assets/images/tempicon1.png';

import './movinglogo.css'

const cardData = [
  { image: nodeJsImage, title: 'Node JS' },
  { image: expressJsImage, title: 'Express JS' },
  { image: mongooseImage, title: 'Mongoose' },
  { image: postmanImage, title: 'Postman' },
  { image: vsCodeImage, title: 'VS Code' },
  { image: awsImage, title: 'AWS' },
  { image: htmlImage, title: 'HTML' },
  { image: cssImage, title: 'CSS' },
  { image: tailwindCssImage, title: 'Tailwind CSS' },
  { image: dockerImage, title: 'Docker' },
  { image: kubernetesImage, title: 'Kubernetes' },
  { image: gitImage, title: 'Git' },
  { image: githubImage, title: 'GitHub' },
  { image: netlifyImage, title: 'Netlify' },
];

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    arrows:false,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ]
  };

  return (
    <div className="container">
      <text className='overview2TitleText m-4'> Tools Covered </text>
      <text className='overview2TitleTextphone'> Tools Covered </text>
      
      <Slider {...settings} >

        {cardData.map((card, index) => (
          <div key={index} className='cardContainer'>
            <Card image={card.image} title={card.title} />
          </div>
        ))}
     
      </Slider>
    </div>
  );
};

export default Carousel;
