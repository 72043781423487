import React from 'react'
import Navigation from './Navigation/Navigation'
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div>
     <Navigation/>
    </div>
  )
}

export default App
