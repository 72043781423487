import React from 'react'
import './PracticePlatforms.css'
import image from '../../../assets/images/tempicon1.png'
function PracticePlatforms() {
    return (
        <div className='PracticePlatformsContainer'>
            <h2 className="overview2TitleText">Unlimited Access to all our Practice Platforms</h2>
            <h2 className="overview2TitleTextphone">Unlimited Access to all our Practice Platforms</h2>
            <div className="platformContainer">
                <div className="platformDiv">
                    <h6>
                        Codekata
                    </h6>

                    <img className='platformDivImage' src={image} />

                    <p>
                        A tool-kit specifically developed to boost
                        the coding skills and makes you ever-ready to crack interviews.
                    </p>
                </div>
                <div className="platformDiv">
                    <h6>
                        IDE
                    </h6>


                    <img className='platformDivImage' src={image} />



                    <p>
                        GUVI IDE is an Integrated Development Environment that lets you write, edit, run, test & debug your code.
                    </p>
                </div>
                <div className="platformDiv">
                    <h6>
                        WebKata
                    </h6>


                    <img className='platformDivImage' src={image} />


                    <p>
                        A cloud-based module to hone your front end skills without any hassle of local environment setup.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default PracticePlatforms
