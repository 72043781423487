import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Projects.css';

const projects = [
  {
    title: "Music Streaming App",
    description: "Build your own version of Spotify, where you can search, play, and curate music playlists. You will learn more about real-time streaming tech and how to manage user accounts smoothly.",
  },
  {
    title: "Khatabook Clone – Personal Money Manager",
    description: "Create a personal finance tracker like Khatabook that allows users to monitor their income, expenses, and savings goals. Users can categorize transactions, generate reports, and set budget limits. Include features like data visualization for spending trends, user authentication, and secure data storage.",
  },
  {
    title: "Build Flipkart Clone using MERN stack",
    description: "Build an e-commerce website where users can browse products, add them to their cart, and make purchases. The frontend will feature product listings, search functionality, shopping cart management, and checkout processes.",
  },
  {
    title: "Custom Gmail using Gmail API",
    description: "Bored of your Gmail UI? Build your own frontend for Gmail by accessing it through the Gmail API. This project will teach you how to do API integration and OAuth.",
  },
  {
    title: "Custom Gmail using Gmail API",
    description: "Bored of your Gmail UI? Build your own frontend for Gmail by accessing it through the Gmail API. This project will teach you how to do API integration and OAuth.",
  },
];

const ProjectsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3.9,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="projects-carousel">
      <h2 className="overview2TitleText">Projects</h2>
      <h2 className="overview2TitleTextphone">Projects</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="card3">
            <h3>{project.title}</h3>
            <p>{project.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProjectsCarousel;
