import React from 'react'
import './Certifications.css'

function Certifications() {
  return (
    <div className='CertificationsContainer'>
     <div className="CertificationText">
      <h2 >
      IITM Pravartak Certification & more
      </h2>
      <text className='CertificationSubText' >
      Earn Full-stack development certifications including IITM Pravartak, NSDC, GUVI, and more, validating proficiency in prominent programming languages and frameworks.
      </text>

     </div>
     <div className="CertificationImage">

     </div>

     <button className='CertificationButton'>
      Enroll Now
     </button>
    </div>
  )
}

export default Certifications
