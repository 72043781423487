import React from 'react';
import OverView from '../Overview/OverView';
import Certifications from '../Certifications/Certifications';
import Syllabus from '../Syllabus/Syllabus';
import Placements from '../Placements/Placements';
import FAQuestions from '../FAQuestions/FAQuestions';
import './Dashboard.css'
import AboutOverView from '../Overview/AboutOverView';
import Movinglogo from '../Overview/movingLogo/movinglogo'
import Downloadsyllabus from '../Overview/DownloadSyallabus/Downloadsyllabus';
import ConnectWithExperts from '../Overview/ConnectWithExperts/ConnectWithExperts'
import EnrollNow from '../Syllabus/EnrollNow/EnrollNow'
import Projects from '../Syllabus/Projects/Projects'
import Accreditations from '../Syllabus/Accreditations/Accreditations'
import HappyLearners from '../Certifications/HappyLearners/HappyLearners'
import CampusCompany from '../Placements/CampusCompany/CampusCompany'
import OurLearners from '../Placements/OurLearners/OurLearners'
import PracticePlatforms from '../Placements/PracticePlatforms/PracticePlatforms';



function Dashboard() {
  return (
    <div className='dashboard'>
      <section id="overview">
        <OverView />
        <AboutOverView/>


        <div className="Carousel1">
        <Movinglogo/>
        </div>

        <Downloadsyllabus/>


        <div className="connect1">

        <ConnectWithExperts/>

        </div>


       
      </section>
  
      <section id="syllabus">
        <Syllabus />
        <EnrollNow/>
        <div className="Projects1">
        <Projects/>
        </div>
          <div className="Accreditations">
        <Accreditations/>
        </div>

      </section>
      <section id="certification">
        <Certifications />



        <div className="carousel-wrapper">
      <div className="carousel-foggy-left"></div>
      <div className="carousel-foggy-right"></div>

        <HappyLearners/>
        </div>
      </section>
      <section id="placement">
        <Placements />

        <CampusCompany/>

        <OurLearners/>

        <PracticePlatforms/>
      </section>
      <section id="faq">
        <FAQuestions />
      </section>
      
    </div>
  );
}

export default Dashboard;
