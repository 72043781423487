import React, { useState } from 'react';
import './Syllabus.css';

const modules = [
  { title: 'Module 1', content: 'Content for Module 1...' },
  { title: 'Module 2', content: 'Content for Module 2...' },
  { title: 'Module 3', content: 'Content for Module 3...' },
  { title: 'Module 4', content: 'Content for Module 4...' },
  { title: 'Module 5', content: 'Content for Module 5...' },
  { title: 'Module 6', content: 'Content for Module 6...' },
  { title: 'Module 7', content: 'Content for Module 7...' },
  { title: 'Module 8', content: 'Content for Module 8...' }
];

const App = () => {
  const [activeModule, setActiveModule] = useState(null);

  const toggleModule = (index) => {
    if (activeModule === index) {
      setActiveModule(null);
    } else {
      setActiveModule(index);
    }
  };

  return (
    <div className="container2">
      <h1 className='overview2TitleText'>Syllabus Overview</h1>
      <h1 className='overview2TitleTextphone'>Syllabus Overview</h1>
      <p>Our curriculum has been thoughtfully structured by industrial experts to cover the essentials of Front-end, Back-end & Database technologies</p>
      <div className="modules">
        {modules.map((module, index) => (
          <div key={index} className="module">
            <div className="module-header" onClick={() => toggleModule(index)}>
              <span>{module.title}</span>
              <span>{activeModule === index ? '▲' : '▼'}</span>
            </div>
            {activeModule === index && (
              <div className="module-content">
                <p>{module.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <button className="downloadsyllabusbutton">Download Syllabus</button>
    </div>
  );
};

export default App;
