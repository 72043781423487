import React from 'react'
import './Footer.css'
import { IoCall } from "react-icons/io5";

function Footer() {
  return (
    <div className='footerContainer'>
      

      <div className="footerTextContainer">
        <h2>
        Still have queries? Contact Us
        </h2>
        <h6>
        Request a callback. An expert from the admission office will call you in the next 24 working hours.

        </h6>
        <h6>
        You can also reach out to us at
        <span className='footerBoldText'> cs@guvi.in </span> 
         or 
         <span className='footerBoldText'> 9736097320 </span>

        </h6>
      </div>
      <div className="footerButtonContainer">
        <button className='RequestCallbackButton'>
        <IoCall className='RequestCallbackButtonIcon' />
        <span>Request callback</span>
        </button>
        <button className='CallUsButton'>
        <IoCall className='CallUsButtonIcon' />
        <span className='CallUsButtonspan'>Call us</span>
        </button>
      </div>
    </div>
  )
}

export default Footer
