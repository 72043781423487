import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import isoLogo from '../../../assets/images/tempicon1.png';
import googleLogo from '../../../assets/images/tempicon1.png';
import nasscomLogo from '../../../assets/images/tempicon1.png';
import './Accreditations.css';

const AccreditationCarousel = () => {
  const settings = {
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 0,
    // cssEase: 'linear',
    arrows: false,
    pauseOnHover: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ]
  };

  return (
    <div className="accreditation-carousel">
      <h2>We are Accredited by</h2>
      <Slider {...settings}>
        <div className='accreditationimg1'>
          <img src={isoLogo} alt="ISO 27001-9001 Certified" />
        </div>
        <div>
          <img src={googleLogo} alt="Google for Education Partner" />
        </div>
        <div>
          <img src={nasscomLogo} alt="NASSCOM" />
        </div>
        <div>
          <img src={isoLogo} alt="ISO 27001-9001 Certified" />
        </div>
        <div>
          <img src={googleLogo} alt="Google for Education Partner" />
        </div>
        <div>
          <img src={nasscomLogo} alt="NASSCOM" />
        </div>
      </Slider>
    </div>
  );
};

export default AccreditationCarousel;
